"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonLayout = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Footer_1 = require("./components/Footer");
const Header_1 = require("./components/Header");
const CommonLayout = ({ children }) => {
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(Header_1.Header, {}), children, (0, jsx_runtime_1.jsx)(Footer_1.Footer, {})] }));
};
exports.CommonLayout = CommonLayout;
