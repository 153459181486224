"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const WorkItem = ({ work }) => {
    //タイトルを出力
    const itemGithub = () => {
        return ((0, jsx_runtime_1.jsx)("a", Object.assign({ href: work.github ? work.github : '', target: "_blank", rel: "noopener noreferrer" }, { children: "GitHub" })));
    };
    return ((0, jsx_runtime_1.jsxs)("li", { children: [(0, jsx_runtime_1.jsx)("a", Object.assign({ href: work.url, target: "_blank", rel: "noopener noreferrer" }, { children: work.title })), (0, jsx_runtime_1.jsx)("span", { children: "--" }), (0, jsx_runtime_1.jsx)("a", Object.assign({ href: work.url, target: "_blank", rel: "noopener noreferrer" }, { children: work.comment })), work.github ? itemGithub() : ''] }));
};
exports.WorkItem = WorkItem;
