"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Router = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_router_dom_1 = require("react-router-dom");
const CommonLayout_1 = require("../layouts/CommonLayout");
const about_1 = require("../pages/about");
const contact_1 = require("../pages/contact");
const notfound_1 = require("../pages/notfound");
const site_1 = require("../pages/site");
const top_1 = require("../pages/top");
const Router = () => ((0, jsx_runtime_1.jsx)(react_router_dom_1.BrowserRouter, { children: (0, jsx_runtime_1.jsxs)(react_router_dom_1.Switch, { children: [(0, jsx_runtime_1.jsx)(react_router_dom_1.Route, Object.assign({ exact: true, path: "/" }, { children: (0, jsx_runtime_1.jsx)(CommonLayout_1.CommonLayout, { children: (0, jsx_runtime_1.jsx)(top_1.TopPage, {}) }) })), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, Object.assign({ exact: true, path: "/about" }, { children: (0, jsx_runtime_1.jsx)(CommonLayout_1.CommonLayout, { children: (0, jsx_runtime_1.jsx)(about_1.AboutPage, {}) }) })), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, Object.assign({ exact: true, path: "/site" }, { children: (0, jsx_runtime_1.jsx)(CommonLayout_1.CommonLayout, { children: (0, jsx_runtime_1.jsx)(site_1.SitePage, {}) }) })), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, Object.assign({ exact: true, path: "/contact" }, { children: (0, jsx_runtime_1.jsx)(CommonLayout_1.CommonLayout, { children: (0, jsx_runtime_1.jsx)(contact_1.ContactPage, {}) }) })), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { children: (0, jsx_runtime_1.jsx)(CommonLayout_1.CommonLayout, { children: (0, jsx_runtime_1.jsx)(notfound_1.NotFoundPage, {}) }) })] }) }));
exports.Router = Router;
