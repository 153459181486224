"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopPage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const SearchForm_1 = require("./components/SearchForm");
const WorkList_1 = require("./components/WorkList");
const react_1 = require("react");
const TopPage = () => {
    const [works, setWorks] = (0, react_1.useState)([]);
    const updateWorks = (data) => setWorks(data);
    return ((0, jsx_runtime_1.jsxs)("main", Object.assign({ id: "top" }, { children: [(0, jsx_runtime_1.jsx)(SearchForm_1.SearchForm, { updateWorks: updateWorks }), (0, jsx_runtime_1.jsx)(WorkList_1.WorkList, { works: works, updateWorks: updateWorks })] })));
};
exports.TopPage = TopPage;
